import React, { useEffect, useState } from "react";
import {
  FaShoppingBag,
  FaUser,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaTruck,
  FaMoneyBillWave,
  FaFileInvoice,
} from "react-icons/fa";
import { CONST_DATA } from "../../common.js/constData";
import { LableItem } from "../LabelLanguge/LabelItem";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeToPayLater,
  GetDeliveryItems,
  GetOrderById,
} from "../../services/OrderServices";
import { DateToString, formatDateTime } from "../../utils/DatetimeHelper";
import CurrencyComponent from "../Currency/CurrencyFormat";
import { getStatusIcon } from "../../utils/orderUiHelper";
import { useParams } from "react-router-dom";
import {
  FaCheckCircle,
  FaShippingFast,
  FaBox,
  FaCreditCard,
  FaClock,
} from "react-icons/fa";
import { motion } from "framer-motion";
import { CreatePaymentByOrder } from "../../services/PaymentServices";
import { toast } from "react-toastify";
import BizError from "../../common.js/BizError";
import CashButton from "../home/PaymentIcon/CashButton";
import PayWithMomoButton from "../home/PaymentIcon/PayWithMomoButton";
const OrderPayment = () => {
  const dispatch = useDispatch();
  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [deliExpectation, setDeliExpectation] = useState("");
  const [orderDetail, setOrderDetail] = useState(null);
  const [products, setProducts] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [paidAmt, setPaidAmount] = useState(0);
  const [loadingPayNow, setLoadingPayNow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const isEn = useSelector((state) => state.FoxReducer.En);

  const handlePayNow = async (e) => {
    try {
      setLoadingPayNow(true);

      let response = await CreatePaymentByOrder(orderDetail);
      if (response.statusCode === CONST_DATA.PAYMENT.PAYMENT_SUCCESS_CODE) {
        window.location.href = response.data.payUrl;
      } else {
        toast.error(response.statusMessage, { autoClose: 10000 });
      }
      setLoadingPayNow(false);
    } catch (error) {
      if (error instanceof BizError) {
        toast.error(error.message);
      } else {
        console.log(error);
        toast.error("Somthing went wrong....");
      }
    } finally {
      setLoadingPayNow(false);
    }
  };
  const handlePayLater = async (e) => {
    try {
      setLoading(true);
      orderDetail.order_status = CONST_DATA.ORDER.PAY_LATER_STATUS;
      const transformedOrder = {
        order: {
          order_id: orderDetail.order_id.toString(),
          order_status: CONST_DATA.ORDER.PAY_LATER_STATUS,
          customer_no: orderDetail.customer_no,
        },
      };
      let response = await ChangeToPayLater(transformedOrder);
      window.location.reload();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let price = 0;
    products.forEach((item) => {
      price += item.price * item.quantity;
    });
    setTotalAmt(price);
  }, [products]);

  useEffect(() => {
    const getOrderDetail = async () => {
      try {
        let response = await GetOrderById(id);
        let deliveryItemsResponse = await GetDeliveryItems();
        let deliveryItems = [];
        if (deliveryItemsResponse.success) {
          deliveryItems = deliveryItemsResponse.results;
        }
        if (response.success) {
          setOrderDetail(response.results[0]);
          if (response.results[0].Invoices.length > 0) {
            setInvoice(response.results[0].Invoices[0]);
          }
          let orderItems = response.results[0].order_items.map((item) => {
            if (
              deliveryItems.find(
                (o) => o.charge_item === item.Product.product_code
              )
            ) {
              setShippingCharge(item.total_price_in_vat);
              return {
                price: item.total_price_in_vat / item.order_qty,
                quantity: item.order_qty,
                image: item.ProductImages[0]?.image ?? CONST_DATA.DEFAULT_IMG,
                name: isEn
                  ? item.Product.short_desc_en
                  : item.Product.short_desc_local,
                seq: item.item_seq,
                is_delivery: true,
              };
            } else {
              return {
                price: item.total_price_in_vat / item.order_qty,
                quantity: item.order_qty,
                image: item.ProductImages[0]?.image ?? CONST_DATA.DEFAULT_IMG,
                name: isEn
                  ? item.Product.short_desc_en
                  : item.Product.short_desc_local,
                seq: item.item_seq,
                is_delivery: false,
                unit_name: isEn ? item.mmunit_en : item.mmunit,
              };
            }
          });

          setProducts(orderItems);
          let orderDetail = response.results[0];
          let deliDate = new Date(orderDetail.delivery_date);
          let deliExp = `Ngày ${DateToString(deliDate)} từ  ${
            orderDetail.delivery_time_from
          } đến ${orderDetail.delivery_time_to}`;
          setDeliExpectation(deliExp);
          const totalActualAmount = response.results[0].payment_list.reduce(
            (sum, payment) => sum + payment.ActualAmount,
            0
          );
          setPaidAmount(totalActualAmount);
        }
      } catch (err) {
        console.log(err);
      }
    };

    // setCity(checkOutInformation.city.l_name);
    getOrderDetail();
  }, [dispatch]);

  return orderDetail ? (
    <>
      <div className="max-w-container mx-auto px-4 pt-5 md:pt-0">
        <div className="border-b mb-20"></div>
        <div className="border-b pb-4 mb-6 md:mt-100 ">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              <LableItem code="C.OrderDetail.Title"> </LableItem>{" "}
              {orderDetail.order_id}/{orderDetail.order_no}
            </h2>

            <span
              className={`inline-flex items-center px-2 py-0.5 rounded-full text-sm font-semibold 
              )}`}
            >
              {getStatusIcon(orderDetail.OrderStatus.status_code)}
              <span className="ml-1">
                {isEn
                  ? orderDetail.OrderStatus.status_name_en
                  : orderDetail.OrderStatus.status_name_local}
              </span>
            </span>
          </div>
          <p className="text-gray-600">
            <LableItem code="C.OrderDetail.OrderDate"></LableItem>:{" "}
            {formatDateTime(new Date(orderDetail.created_date.replace('Z', '')))}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Buyer Information */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h2 className="text-xl font-semibold mb-4 flex items-center">
              <FaUser className="mr-2" />{" "}
              <LableItem code="C.OrderConfirm.UserInformation"></LableItem>
            </h2>
            <div className="space-y-3">
              <p className="flex items-center">
                <FaUser className="mr-2 text-gray-600" />{" "}
                {orderDetail.receiver_name}
              </p>
              <p className="flex items-center">
                <FaEnvelope className="mr-2 text-gray-600" />{" "}
                {orderDetail.delivery_email}
              </p>
              <p className="flex items-center">
                <FaPhone className="mr-2 text-gray-600" />{" "}
                {orderDetail.receiver_phone}
              </p>
            </div>
          </div>

          {/* Order Status */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h2 className="text-xl font-semibold mb-4 flex items-center">
              <FaTruck className="mr-2" />{" "}
              <LableItem code="C.OrderDetail.DeliveryInfomation"></LableItem>
            </h2>
            <div className="space-y-3">
              <p className="flex items-center">
                <FaMapMarkerAlt className="mr-2 text-gray-600" />{" "}
                {orderDetail.delivery_address}
              </p>
              <p className="flex items-center">
                <span className="font-semibold mr-2">
                  <LableItem code="C.OrderConfirm.DeliExpectation"></LableItem>:
                </span>
                {deliExpectation}
              </p>
              <p className="flex items-center">
                <span className="font-semibold mr-2">
                  {" "}
                  <LableItem code="C.OrderDetail.Note"></LableItem>:
                </span>
                {orderDetail.delivery_comment}
              </p>
            </div>
          </div>
        </div>

        {/* Order Items */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaShoppingBag className="mr-2" />{" "}
            <LableItem code="C.OrderDetail.OrderItems"></LableItem>
          </h2>
          <div className="space-y-4">
            {products
              .filter((item) => item.is_delivery === false)
              .map((item) => (
                <div
                  key={item.seq}
                  className="flex items-center border rounded-lg p-4"
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-20 h-20 object-cover rounded"
                    onError={(e) => {
                      e.target.src = CONST_DATA.DEFAULT_IMG;
                      e.target.alt = "Fallback product image";
                    }}
                  />
                  <div className="ml-4 flex-grow grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <h4 className="font-semibold">{item.name}</h4>
                      <p className="text-gray-600">
                        <CurrencyComponent
                          price={item.price}
                        ></CurrencyComponent>
                        / {item.unit_name}
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-600">
                        <LableItem code="C.Multi.Quantity"></LableItem> {":  "}
                        {item.quantity}
                      </p>
                    </div>

                    <div>
                      <p>
                        <LableItem code="C.OrderDetail.RowTotal"></LableItem>
                        {":  "}
                        <span className="text-gray-800 font-bold">
                          <CurrencyComponent
                            price={item.quantity * item.price}
                          ></CurrencyComponent>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Payment Details */}
        <div className="bg-gray-50 p-4 rounded-lg mb-4">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaMoneyBillWave className="mr-2" />{" "}
            <LableItem code="C.OrderDetail.PaymentDetails"></LableItem>
          </h2>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>
                {" "}
                <LableItem code="C.OrderDetail.Total"></LableItem>:
              </span>
              <span>
                <CurrencyComponent
                  price={
                    orderDetail.total_price_ex_vat +
                    orderDetail.total_vat -
                    shippingCharge
                  }
                ></CurrencyComponent>
              </span>
            </div>
            <div className="flex justify-between">
              <span>
                {" "}
                <LableItem code="C.OrderDetail.ShippingFee"></LableItem>:
              </span>
              <span>
                <CurrencyComponent price={shippingCharge}></CurrencyComponent>
              </span>
            </div>
            <div className="flex justify-between font-bold text-lg pt-2 border-t">
              <span>
                {" "}
                <LableItem code="C.OrderDetail.GrandTotal"></LableItem>:
              </span>
              <span>
                <CurrencyComponent
                  price={orderDetail.total_price_ex_vat + orderDetail.total_vat}
                ></CurrencyComponent>
              </span>
            </div>

            <div className="flex justify-between font-bold text-lg pt-2 border-t">
              <span>
                {" "}
                <LableItem code="C.OrderDetail.PaidAmount"></LableItem>:
              </span>
              <span>
                <CurrencyComponent price={paidAmt}></CurrencyComponent>
              </span>
            </div>
          </div>
        </div>
        {/* Invoice Information */}
        {orderDetail.Invoices.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-6 mb-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h2 className="text-xl font-semibold mb-4 flex items-center">
                  <FaFileInvoice className="mr-2" />
                  <LableItem code="C.OrderDetail.InvoiceInformation"></LableItem>
                </h2>
                <div className="space-y-3">
                  <p className="flex items-center">
                    <span className="font-semibold mr-2">
                      <LableItem code="C.OrderDetail.InvoiceNumber"></LableItem>
                      :
                    </span>
                    {invoice.einv_inv_no || "N/A"}
                  </p>
                  <p className="flex items-center flex-wrap">
                    <span className="font-semibold mr-2 flex-wrap">
                      <LableItem code="C.OrderDetail.Url"></LableItem>:
                    </span>
                    <span className="break-words">
                      {invoice.einv_url || "N/A"}
                    </span>
                  </p>
                  <p className="flex items-center">
                    <span className="font-semibold mr-2">
                      <LableItem code="C.OrderDetail.Secret"></LableItem>:
                    </span>
                    {invoice.einv_secret || "N/A"}
                  </p>
                  <p className="flex items-center">
                    <FaMapMarkerAlt className="mr-2 text-gray-600" />
                    {orderDetail.invoice_address || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {orderDetail.order_status === CONST_DATA.ORDER.PAY_NOW_STATUS ? (
          <div className="flex justify-end space-x-4 mb-4 mt-0">
            <CashButton
              onClick={handlePayLater}
              isLoading={loading}
            ></CashButton>
            <PayWithMomoButton
              onClick={handlePayNow}
              isLoading={loadingPayNow}
            ></PayWithMomoButton>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="flex justify-end space-x-4 mb-4 mt-5"></div>
    </>
  ) : (
    <></>
  );
};

export default OrderPayment;
