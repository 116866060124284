import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../pageProps/Breadcrumbs";

import ItemCardReadOnly from "../../pages/Cart/ItemCardReadOnly";
import { LableItem } from "../LabelLanguge/LabelItem";

import CurrencyComponent from "../Currency/CurrencyFormat";
import { useContentLangByCode } from "../contentLanguage/ContentFactory";
import { GetDeliveryItems, GetOrderById } from "../../services/OrderServices";
import { useParams } from "react-router-dom";
import { CONST_DATA } from "../../common.js/constData";
import { DateToString } from "../../utils/DatetimeHelper";
//import { useContentLangByCode } from "../contentLanguage/ContentFactory";
const OrderDetail = () => {
  const dispatch = useDispatch();
  //const products = useSelector((state) => state.FoxReducer.products);
  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [deliExpectation, setDeliExpectation] = useState("");
  const [orderDetail, setOrderDetail] = useState(null);
  const [products, setProducts] = useState([]);

  const { id } = useParams();

  const isEn = useSelector((state) => state.FoxReducer.En);

  useEffect(() => {
    let price = 0;
    products.forEach((item) => {
      price += item.price * item.quantity;
    });
    setTotalAmt(price);
  }, [products]);

  useEffect(() => {
   
    const getOrderDetail = async () => {
      let response = await GetOrderById(id);
      let deliveryItemsResponse = await GetDeliveryItems();
      let deliveryItems = [];
      if (deliveryItemsResponse.success) {
        deliveryItems = deliveryItemsResponse.results;
      }
      if (response.success) {
        setOrderDetail(response.results[0]);
        
        let orderItems = response.results[0].order_items.map((item) => {
          if (
            deliveryItems.find(
              (o) => o.charge_item === item.Product.product_code
            )
          ) {
            setShippingCharge(item.total_price_in_vat);
            return {
              price: item.total_price_in_vat / item.order_qty,
              quantity: item.order_qty,
              image: item.ProductImages[0]?.image ?? CONST_DATA.DEFAULT_IMG,
              name: isEn
                ? item.Product.short_desc_en
                : item.Product.short_desc_local,
              seq: item.item_seq,
              is_delivery: true,
            };
          } else {
            return {
              price: item.total_price_in_vat / item.order_qty,
              quantity: item.order_qty,
              image: item.ProductImages[0]?.image ?? CONST_DATA.DEFAULT_IMG,
              name: isEn
                ? item.Product.short_desc_en
                : item.Product.short_desc_local,
              seq: item.item_seq,
              is_delivery: false,
              unit_name: isEn ? item.mmunit_en : item.mmunit,
            };
          }
        });
        
        setProducts(orderItems);
        
        let orderDetail=response.results[0];
        let deliDate = new Date(orderDetail.delivery_date);
        let deliExp = `Ngày ${DateToString(deliDate)} từ  ${orderDetail.delivery_time_from} đến ${orderDetail.delivery_time_to}`;
        setDeliExpectation(deliExp);
      }

      console.log(response.results[0]);
    };
   
    // setCity(checkOutInformation.city.l_name);
    getOrderDetail();
  }, [dispatch]);

  return (
    <div className="max-w-container mx-auto px-4 pt-5 md:pt-0">
      <Breadcrumbs title={useContentLangByCode("C.OrderDetail.Title")} />
      {products.length > 0 ? (
        <div className="pb-20">
          <div className="w-full h-20 bg-[#F5F7F7] text-primeColor hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg font-titleFont font-semibold">
            <h2 className="col-span-2">
              <LableItem code="C.Multi.ProductName"></LableItem>
            </h2>
            <h2>
              <LableItem code="C.Multi.Price"></LableItem>
            </h2>
            <h2>
              <LableItem code="C.Multi.Quantity"></LableItem>
            </h2>
            <h2>
              <LableItem code="C.Multi.SubTotal"></LableItem>
            </h2>
          </div>
          <div className="mt-5">
            {products
              .filter((item) => item.is_delivery === false)
              .map((item) => (
                <div key={item.seq}>
                  <ItemCardReadOnly item={item} />
                </div>
              ))}
          </div>

          {true && (
            <div className="mt-8 space-y-6">
              {/* User Information */}
              <div className="border border-bsmBG rounded-md shadow-sm p-4">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">
                    <LableItem code="C.OrderConfirm.UserInformation"></LableItem>
                  </h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.FullName"></LableItem>:
                      </strong>{" "}
                      {orderDetail.receiver_name}
                    </p>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.Email"></LableItem>:
                      </strong>{" "}
                      {orderDetail.delivery_email}
                    </p>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.Phone"></LableItem>:
                      </strong>{" "}
                      {orderDetail.receiver_phone}
                    </p>
                  </div>
                  <div>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.Address"></LableItem>:
                      </strong>{" "}
                      {orderDetail.delivery_address}
                    </p>
                    {/* <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.City"></LableItem>:
                      </strong>{" "}
                      {city}
                    </p> */}
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.Note"></LableItem>:
                      </strong>{" "}
                      {orderDetail.delivery_comment}
                    </p>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.OrderStatus"></LableItem>
                        :
                      </strong>{" "}
                      {orderDetail.order_status}: {orderDetail.potrtmsg}
                    </p>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.DeliExpectation"></LableItem>
                        :
                      </strong>{" "}
                      {deliExpectation}
                    </p>
                  </div>
                </div>
              </div>

              {/* Pricing */}
              <div className="border border-bsmBG rounded-md shadow-sm p-4">
                <h2 className="text-xl font-semibold mb-4">
                  <LableItem code="C.OrderConfirm.Pricing"></LableItem>
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.Total"></LableItem>:
                      </strong>{" "}
                      <CurrencyComponent
                        price={
                          orderDetail.total_price_ex_vat +
                          orderDetail.total_vat -
                          shippingCharge
                        }
                      ></CurrencyComponent>
                    </p>
                  </div>
                  <div>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.GrandTotal"></LableItem>:
                      </strong>{" "}
                      <CurrencyComponent
                        price={
                          orderDetail.total_price_ex_vat + orderDetail.total_vat
                        }
                      ></CurrencyComponent>
                    </p>
                  </div>
                  {/* <div>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.TotalVat"></LableItem>
                        :
                      </strong>{" "}
                      
                      <CurrencyComponent price={orderDetail.total_vat}></CurrencyComponent>
                    </p>
                    
                  </div> */}
                  <div>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderDetail.ShippingFee"></LableItem>
                        :
                      </strong>{" "}
                      <CurrencyComponent
                        price={shippingCharge}
                      ></CurrencyComponent>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="flex justify-end items-end h-full">
                <LoadingButton
                  onButtonClick={handleComplete}
                  loading={loading}
                  btName={buttonCreate}
                  className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300"
                />
              </div> */}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrderDetail;
