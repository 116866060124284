import React, { useState, useEffect } from "react";
import ImageV2 from "../../designLayouts/ImageV2";
// import Badge from "../../designLayouts/buttons/Badge";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addToCart,
  createOrUpdateCart,
  addToWishList,
  removeFromWishList
} from "../../../redux/FoxSlice";
import { toast } from "react-toastify";
import CurrencyComponent from "../../../components/Currency/CurrencyFormat";
import { useSelector } from "react-redux";
import {
  CreateCart,
  GetCurrentCart,
  AddToCart,
  GetQuantityItemInCart,
  UpdateCardItem,
} from "../../../services/CartServices";

import { CONST_DATA } from "../../../common.js/constData";
import BackendApi from "../../../common.js/backendApi";
import { fetchData } from "../../../common.js/http";
import { trackAddToCart } from '../../../utils/analytics';
const ProductV2 = (props) => {

  const dispatch = useDispatch();
  
  const routeId = props.slug ?? props.product_code;
  // const [wishList, setWishList] = useState([]);
  const _wishlist = useSelector((state) => state.FoxReducer.wishlist) || [];

  const dummny = useSelector((state) => state.FoxReducer.dummyCust);
  const userInfo = useSelector((state) => state.FoxReducer.userInfo);
  const En = useSelector((state) => state.FoxReducer.En);
  const selectedStore = useSelector(
    (state) => state.FoxReducer.useSelectedStore
  );
  const cart = useSelector((state) => state.FoxReducer.cart);
  const navigate = useNavigate();
  const productItem = props;
  // State để lưu trữ trạng thái màu sắc
  const [isInWishlist, setIsInWishlist] = useState(false);
  useEffect(() => {
    if (_wishlist != null && _wishlist.length > 0 && _wishlist[0] != null) {
      setIsInWishlist(
        _wishlist.some((item) => item.product_code === props.product_code)
      );
    }
    else
    setIsInWishlist(
      false
    );
  }, [_wishlist, props]);



  const handleProductDetails = () => {
    navigate(`/product/${routeId}`, {
      state: {
        item: productItem,
      },
    });
  };


  const addWishList = async () => {
    try {
      let props_api = {
        url: `${BackendApi.AddToWishListByCust.url}`,
        method: BackendApi.AddToWishListByCust.method,
        body: {
          product_code: props.product_code,
          channel_code: CONST_DATA.CHANNEL
        }
      };
      const data1 = await fetchData(props_api);
      if (data1.success) {
        dispatch(addToWishList({ product_code: props.product_code }));
        toast.success(data1.message);
      }
      else
        toast.error(data1.message);
    } catch (err) {
      toast.error(En ? "Add error" : "Thêm lỗi");
      
      //toast.error(error);
    }
  };

  const removeWishList = async () => {
    try {
      let props_api = {
        url: `${BackendApi.RemoveFromWishListByCust.url}`,
        method: BackendApi.RemoveFromWishListByCust.method,
        body: {
          product_code: props.product_code,
          channel_code: CONST_DATA.CHANNEL
        }
      };
      const data1 = await fetchData(props_api);
      if (data1.success) {
        dispatch(removeFromWishList({ product_code: props.product_code }));
        toast.success(data1.message);
      }
      else
      {
        //dispatch(removeFromWishList({ product_code: props.product_code }));
        toast.error(data1.message);
      }
    } catch (err) {
      //toast.error(error);
      toast.error(En ? "Remove lỗi" : "Xóa lỗi.");
    }
  };


  const handleWishList = () => {
    if (isInWishlist) {
      removeWishList();
      //dispatch(removeFromWishList(props));
    } else {
      addWishList();
      //dispatch(addToWishList(props));
    }

  };
  const CreateOrUpdateCartInfo = async () => {
    
    if (userInfo !== null) {
      return await CreateOrUpdateCartInfoAfterLogin();
    }
    return await CreateOrUpdateCartInfoBeforeLogin();
  };
  const CreateOrUpdateCartInfoAfterLogin = async () => {
    let customer_no = userInfo.customers[0].customer_no;

    return await CreateCartInfo(customer_no);
  };
  const CreateOrUpdateCartInfoBeforeLogin = async () => {
    if (cart === null) {
      return await CreateCartInfo(dummny);
    }
    return cart;
  };
  const CreateCartInfo = async (customer_no) => {
    let cartOnServer = await GetCurrentCart(
      customer_no,
      CONST_DATA.STORE_DEFAULT
    );

    var cart_id = 0;
    if (cartOnServer.results.length > 0) {
      cart_id = cartOnServer.results[0].id;
    } else {
      let cart_data = await CreateCart(customer_no, CONST_DATA.STORE_DEFAULT);
      cart_id = cart_data.results[0].id;
    }

    dispatch(
      createOrUpdateCart({ cart_id: cart_id, customer_no: customer_no })
    );
    let newCartInfo = {
      cart_id: cart_id,
      customer_no: customer_no,
    };
    return newCartInfo;
  };
  const AddToCartOnServer = async (newCart) => {
    try {
      let quantity = 1;
      let cartItem = {
        product_code: props.product_code,
        quantity: quantity,
        price: props.retail_price,
        channel_code: CONST_DATA.CHANNEL,
        discount: 0,
        discount_info: null,
        vat_rate: props.vat_rate,
        vat_code: props.vat_code,
        vat_amount: props.price - props.price_ex_vat,
        total: quantity * props.price,
        cart_id: newCart.cart_id,
        //cart===null?localCartId: cart?.cart_id,
        customer_no: newCart.customer_no,
        //cart===null?localCustNo:cart.customer_no
      };

      let existsQuantity = await GetQuantityItemInCart(
        newCart.customer_no,
        selectedStore.store_code,
        props.product_code
      );

      let response;
      if (existsQuantity === 0) {
        response = await AddToCart(cartItem);
      } else {
        cartItem.quantity = existsQuantity + 1;
        response = await UpdateCardItem(cartItem);
      }
      if (response.success) {
        toast.success(response.message);
      } else toast.error(response.message);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };
  const handleAddToCart = async (e) => {
    try {
      
      let cartData = await CreateOrUpdateCartInfo();
      
      await AddToCartOnServer(cartData);

      dispatch(
        addToCart({
          product_code: props.product_code,
          name: props.name,
          quantity: 1,
          image: props.image,
          badge: props.badge,
          price: props.price,
          price_ex_vat: props.price_ex_vat,
          retail_price_ex_vat: props.retail_price_ex_vat,
          retail_price: props.retail_price,
          vat_code: props.vat_code,
          vat_rate: props.vat_rate,
          colors: props.color,
        })
      );

      trackAddToCart(props.product_code,props.name,props.retail_price);

    } catch (error) {
      //todo
    }
  };
  return (
    // <div className="bg-white rounded-xl cursor-pointer hover:scale-[1.03] transition-all relative overflow-hidden max-w-screen-sm">
    <div className="bg-skubg rounded-xl shadow-lg overflow-hidden transform transition-transform hover:scale-105 focus-within:ring-2 focus-within:ring-bsmBoder h-full">
      <div className="flex flex-col h-full w-full">
        <div onClick={handleWishList} 
          className="bg-bsmBG w-10 h-10 flex items-center justify-center rounded-full cursor-pointer absolute top-2 right-2 z-10" style={{color: isInWishlist ? 'red' : 'lightgray'}}>
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path></svg>
        </div>

        <div className="w-full overflow-hidden mx-auto aspect-w-16 aspect-h-8" onClick={handleProductDetails}>
          <ImageV2 className="w-full h-full object-cover" imgSrc={props.image} />
        </div>

        {/* <div className="text-center bg-primeColorDark p-2 flex-grow w-full">
          <h3 className="text text-black font-bold" onClick={handleProductDetails}>{props.name}</h3>
          <h4 className="text text-skuprice mt-2 font-bold">
            <CurrencyComponent price={props.retail_price}></CurrencyComponent> / {props.unit}
          </h4>
        </div> */}

        <div className="text-center bg-primeColorDark p-2 flex-grow w-full">
          <h3 className="text text-black font-bold" onClick={handleProductDetails}>{props.name}</h3>
          <h4 className="text text-skuprice mt-2 font-bold">
            <CurrencyComponent price={props.retail_price}></CurrencyComponent> / {props.unit} 1
          </h4>
          {props.price - props.retail_price > 1 && (
            <div className="text text-gray-500 mt-2">
              <span className="line-through">
                <CurrencyComponent price={props.price}></CurrencyComponent>
              </span>
              <span className="text-red-500 ml-2">
                {`-${Math.round(((props.price - props.retail_price) / props.price) * 100)}%`}
              </span>
            </div>
          )}
        </div>

        <button type="button" onClick={handleAddToCart}
           className="w-full flex items-center justify-center gap-2 mt-auto px-3 py-2 bg-skubutton text-secondTextColor font-semibold rounded-b-xl">
          <svg className="text-secondTextColor" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 512 512">
            <path d="M164.96 300.004h.024c.02 0 .04-.004.059-.004H437a15.003 15.003 0 0 0 14.422-10.879l60-210a15.003 15.003 0 0 0-2.445-13.152A15.006 15.006 0 0 0 497 60H130.367l-10.722-48.254A15.003 15.003 0 0 0 105 0H15C6.715 0 0 6.715 0 15s6.715 15 15 15h77.969c1.898 8.55 51.312 230.918 54.156 243.71C131.184 280.64 120 296.536 120 315c0 24.812 20.188 45 45 45h272c8.285 0 15-6.715 15-15s-6.715-15-15-15H165c-8.27 0-15-6.73-15-15 0-8.258 6.707-14.977 14.96-14.996zM477.114 90l-51.43 180H177.032l-40-180zM150 405c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm167 15c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0" data-original="#000000"></path>
          </svg>
          {En ? "Buy" : "Mua"}
        </button>
      </div>
    </div>

  );
};

export default ProductV2;
