import React, { useEffect, useState } from "react";
import {
  FaShoppingBag,
  FaUser,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaTruck,
  FaMoneyBillWave,
} from "react-icons/fa";
import { CONST_DATA } from "../../common.js/constData";
import { LableItem } from "../LabelLanguge/LabelItem";
import Breadcrumbs from "../pageProps/Breadcrumbs";
import { GetContentLangByCode, useContentLangByCode } from "../contentLanguage/ContentFactory";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetDeliveryItems, GetOrderById } from "../../services/OrderServices";
import { DateToString, formatDateTime } from "../../utils/DatetimeHelper";
import CurrencyComponent from "../Currency/CurrencyFormat";
import { getStatusIcon } from "../../utils/orderUiHelper";

const OrderDetailV2 = () => {
  const dispatch = useDispatch();
  //const products = useSelector((state) => state.FoxReducer.products);
  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [deliExpectation, setDeliExpectation] = useState("");
  const [orderDetail, setOrderDetail] = useState(null);
  const [products, setProducts] = useState([]);
  const { id } = useParams();

  const isEn = useSelector((state) => state.FoxReducer.En);
  const orderData = {
    orderId: "#ORD123456",
    orderDate: "2024-01-20",
    orderStatus: "In Transit",
    expectedDelivery: "2024-01-25",
    buyerInfo: {
      name: "John Doe",
      email: "john.doe@example.com",
      phone: "+1 234 567 8900",
      address: "123 Main Street, Apt 4B, New York, NY 10001",
    },
    note: "Please leave the package at the front door",
    items: [
      {
        id: 1,
        name: "Wireless Headphones",
        price: 99.99,
        unit: "piece",
        quantity: 2,
        image: CONST_DATA.DEFAULT_IMG,
        total: 199.98,
      },
      {
        id: 2,
        name: "Smart Watch",
        price: 199.99,
        unit: "piece",
        quantity: 1,
        image: CONST_DATA.DEFAULT_IMG,
        total: 199.99,
      },
    ],
    paymentDetails: {
      subtotal: 399.97,
      deliveryFee: 10.0,
      grandTotal: 409.97,
    },
  };
  
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "in transit":
        return "bg-blue-100 text-blue-800";
      case "delivered":
        return "bg-green-100 text-green-800";
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };
  const CreateOrderData = () => {};

  useEffect(() => {
    let price = 0;
    products.forEach((item) => {
      price += item.price * item.quantity;
    });
    setTotalAmt(price);
  }, [products]);

  useEffect(() => {
    
    const getOrderDetail = async () => {
      try {
        let response = await GetOrderById(id);
        let deliveryItemsResponse = await GetDeliveryItems();
        let deliveryItems = [];
        if (deliveryItemsResponse.success) {
          deliveryItems = deliveryItemsResponse.results;
        }
        if (response.success) {
          setOrderDetail(response.results[0]);

          let orderItems = response.results[0].order_items.map((item) => {
            if (
              deliveryItems.find(
                (o) => o.charge_item === item.Product.product_code
              )
            ) {
              setShippingCharge(item.total_price_in_vat);
              return {
                price: item.total_price_in_vat / item.order_qty,
                quantity: item.order_qty,
                image: item.ProductImages[0]?.image ?? CONST_DATA.DEFAULT_IMG,
                name: isEn
                  ? item.Product.full_desc_en
                  : item.Product.full_desc_local,
                seq: item.item_seq,
                is_delivery: true,
              };
            } else {
              return {
                price: item.total_price_in_vat / item.order_qty,
                quantity: item.order_qty,
                image: item.ProductImages[0]?.image ?? CONST_DATA.DEFAULT_IMG,
                name: isEn
                  ? item.Product.full_desc_en
                  : item.Product.full_desc_local,
                seq: item.item_seq,
                is_delivery: false,
                unit_name: isEn ? item.mmunit_en : item.mmunit,
              };
            }
          });

          setProducts(orderItems);
          let orderDetail = response.results[0];
          let deliDate = new Date(orderDetail.delivery_date);
          let deliExp = `Ngày ${DateToString(deliDate)} từ  ${
            orderDetail.delivery_time_from
          } đến ${orderDetail.delivery_time_to}`;
          setDeliExpectation(deliExp);
        }

        console.log(response.results[0]);
      } catch (err) {
        console.log(err);
      }
    };

    // setCity(checkOutInformation.city.l_name);
    getOrderDetail();
  }, [dispatch]);

  return orderDetail ? (
    <>
      <div className="max-w-container mx-auto px-4 pt-5 md:pt-20">
        <Breadcrumbs title={GetContentLangByCode("C.OrderDetail.Title")} />
        <div className="border-b pb-4 mb-6 md:mt-100 ">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-800">
              <LableItem code="C.OrderDetail.Title"> </LableItem>{" "}
              {orderDetail.order_id}/{orderDetail.order_no}
            </h1>

            <span
              className={`inline-flex items-center px-2 py-0.5 rounded-full text-sm font-semibold 
              )}`}
            >
              {getStatusIcon(orderDetail.OrderStatus.status_code)}
              <span className="ml-1">
                {isEn
                  ? orderDetail.OrderStatus.status_name
                  : orderDetail.OrderStatus.status_name_local}
              </span>
            </span>
          </div>
          <p className="text-gray-600">
            <LableItem code="C.OrderDetail.OrderDate"></LableItem>:{" "}
            { formatDateTime(new Date(orderDetail.created_date.replace('Z', '')))}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Buyer Information */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h2 className="text-xl font-semibold mb-4 flex items-center">
              <FaUser className="mr-2" />{" "}
              <LableItem code="C.OrderConfirm.UserInformation"></LableItem>
            </h2>
            <div className="space-y-3">
              <p className="flex items-center">
                <FaUser className="mr-2 text-gray-600" />{" "}
                {orderDetail.receiver_name}
              </p>
              <p className="flex items-center">
                <FaEnvelope className="mr-2 text-gray-600" />{" "}
                {orderDetail.delivery_email}
              </p>
              <p className="flex items-center">
                <FaPhone className="mr-2 text-gray-600" />{" "}
                {orderDetail.receiver_phone}
              </p>
            </div>
          </div>

          {/* Order Status */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h2 className="text-xl font-semibold mb-4 flex items-center">
              <FaTruck className="mr-2" />{" "}
              <LableItem code="C.OrderDetail.DeliveryInfomation"></LableItem>
            </h2>
            <div className="space-y-3">
              <p className="flex items-center">
                <FaMapMarkerAlt className="mr-2 text-gray-600" />{" "}
                {orderDetail.delivery_address}
              </p>
              <p className="flex items-center">
                <span className="font-semibold mr-2">
                  <LableItem code="C.OrderConfirm.DeliExpectation"></LableItem>:
                </span>
                {deliExpectation}
              </p>
              <p className="flex items-center">
                <span className="font-semibold mr-2">
                  {" "}
                  <LableItem code="C.OrderDetail.Note"></LableItem>:
                </span>
                {orderDetail.delivery_comment}
              </p>
            </div>
          </div>
        </div>

        {/* Order Items */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaShoppingBag className="mr-2" />{" "}
            <LableItem code="C.OrderDetail.OrderItems"></LableItem>
          </h2>
          <div className="space-y-4">
            {products
              .filter((item) => item.is_delivery === false)
              .map((item) => (
                <div
                  key={item.seq}
                  className="flex items-center border rounded-lg p-4"
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-20 h-20 object-cover rounded"
                    onError={(e) => {
                      e.target.src = CONST_DATA.DEFAULT_IMG;
                      e.target.alt = "Fallback product image";
                    }}
                  />
                  <div className="ml-4 flex-grow grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div>
                      <h3 className="font-semibold">{item.name}</h3>
                      <p className="text-gray-600">
                        <CurrencyComponent
                          price={item.quantity * item.price}
                        ></CurrencyComponent>
                        <CurrencyComponent
                          price={item.price}
                        ></CurrencyComponent>
                        / {item.unit_name}
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-600">
                        {" "}
                        <LableItem code="C.Multi.Quantity"></LableItem>:
                      </p>
                      <p className="font-medium">{item.quantity}</p>
                    </div>
                    <div>
                      <p className="text-gray-600">
                        <LableItem code="C.OrderDetail.Unit"></LableItem>:
                      </p>
                      <p className="font-medium">{item.unit_name}</p>
                    </div>
                    <div>
                      <p className="text-gray-600">
                        <LableItem code="C.OrderDetail.RowTotal"></LableItem>:
                      </p>
                      <p className="font-semibold text-gray-800">
                        <CurrencyComponent
                          price={item.quantity * item.price}
                        ></CurrencyComponent>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Payment Details */}
        <div className="bg-gray-50 p-4 rounded-lg mb-10">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaMoneyBillWave className="mr-2" />{" "}
            <LableItem code="C.OrderDetail.PaymentDetails"></LableItem>
          </h2>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>
                {" "}
                <LableItem code="C.OrderDetail.Total"></LableItem>:
              </span>
              <span>
                <CurrencyComponent
                  price={
                    orderDetail.total_price_ex_vat +
                    orderDetail.total_vat -
                    shippingCharge
                  }
                ></CurrencyComponent>
              </span>
            </div>
            <div className="flex justify-between">
              <span>
                {" "}
                <LableItem code="C.OrderDetail.ShippingFee"></LableItem>:
              </span>
              <span>
                <CurrencyComponent price={shippingCharge}></CurrencyComponent>
              </span>
            </div>
            <div className="flex justify-between font-bold text-lg pt-2 border-t">
              <span>
                {" "}
                <LableItem code="C.OrderDetail.GrandTotal"></LableItem>:
              </span>
              <span>
                <CurrencyComponent
                  price={orderDetail.total_price_ex_vat + orderDetail.total_vat}
                ></CurrencyComponent>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default OrderDetailV2;
